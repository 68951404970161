// @flow
import React from "react";
import type {translate} from "../i18n/i18n";
import "./StartPage.scss";
import type {Category} from "../models/Category";
import type {VehicleOption} from "../models/VehicleOption";
import {CategorySelector} from "./CategorySelector";
import {I18nSelector} from "./I18nSelector";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom-v5-compat";
import ContrastColorUpdater from "./ContrastColorUpdater";
import {Trans} from "react-i18next";

type StartPageProps = {
    t: translate,
    mainCategories: Category[],
    otherCategories: Category[],
    currentCategory: null,
    onLoad: () => void,
    fuzzy: string,
    vehicleOption: VehicleOption,
    isI18nMenuOpen: boolean,
    availableLanguages: string[],
    toggleI18nSelector: () => void,
    setLanguage: string => void,
    highlightColor: string,
    markerCategories: Category[],
}

const StartPage = (props: StartPageProps) => {
    const {
        vehicleOption,
        onLoad
    } = props;
    const [isCategoryMenuOpen, setIsCategoryMenuOpen] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        onLoad();
    }, [vehicleOption, onLoad, navigate]);

    const renderCategory = (category: Category) => {
        const categoryStyle = {backgroundImage: `url(${category.imageUrl})`};

        const targetUrl = category.categories.length
            ? `/category?category=${category.key}`
            : `/product/?category=${category.key}`;

        return (
            <Link to={targetUrl} className={"category"} key={category.key} data-testid={"categoryLink"}>
                {!!category.imageUrl && <span style={categoryStyle}/>}
                <span style={{color: props.highlightColor}} data-testid={"categoryTitle"}>
					{category.title}
                    <span className={"amount"} data-testid={"categoryCount"}>{category.count || 0}</span>
					<span className={"subTitle"} data-testid={"categorySubTitle"}>
						{category.subTitle || ""}
					</span>
				</span>
            </Link>);
    };

    const renderLanguageSelector = () => {
        return <React.Fragment>
            <I18nSelector availableLanguages={props.availableLanguages}
                          onClose={() => props.toggleI18nSelector()}
                          onSelect={language => props.setLanguage(language)}
                          shouldOpen={props.isI18nMenuOpen}/>
        </React.Fragment>;
    };

    const renderMarkerCategories = () => {
        return props.markerCategories?.some(category => category.count > 0) && (
            props.markerCategories.map(category => (
                <Link to={`/product?marker=SALE`} className="category" key={category.key} data-testid="saleBanner">
                    <span className=" sale-banner"/>
                    <span data-testid={"categoryTitle"}>
                    {category.title}
                        <span className={"amount"} data-testid={"categoryCount"}>{category.count || 0}</span>
                    <span className={"subTitle"} data-testid={"categorySubTitle"}>
                        <Trans i18nKey="category.titleSpecialOffer"/>
                    </span>
                </span>
                </Link>
            ))
        );
    };

    if (!props.mainCategories.length) {
        return <div className={"page startPage list"}>
            {props.otherCategories.map(category => renderCategory(category))}
            {!!props.availableLanguages.length && renderLanguageSelector()}
        </div>;
    }

    const renderMoreButton = () => {
        return <a href onClick={() => setIsCategoryMenuOpen(true)} className={"category"} data-testid={"moreLink"}>
            <span style={{
                backgroundImage: "radial-gradient(circle at bottom left, rgba(0,0,0,1) 0%, rgba(59,59,59,1) 100%)",
                backgroundColor: "black",
                color: "white",
                position: "relative",
            }}>
                <span className={"category-modul"} style={{
                    position: "absolute",
                    display: "block",
                    lineHeight: "110%",
                    top: "18px",
                    left: "18px",
                    fontWeight: "normal",
                    textTransform: "uppercase",
                    backgroundColor: "transparent",
                    fontFamily: "Arial, Helvetica, sans-serif",
                    fontSize: "clamp(1rem, 1vw, 2rem)",
                    padding: "10px",
                }}><Trans i18nKey="misc.more.title2"/></span>
                <span className={"more-category"} style={{
                    position: "absolute",
                    display: "block",
                    lineHeight: "110%",
                    bottom: "18px",
                    left: "18px",
                    fontWeight: "bold",
                    whiteSpace: "pre-wrap",
                    backgroundColor: "transparent",
                    fontSize: "clamp(2rem, 3vw, 3.5rem)",
                    padding: "10px",
                }}><Trans i18nKey="misc.more.title"/></span>

            </span>
            <span style={{color: props.highlightColor}}>
                <span className={"amount"} style={{color: props.highlightColor}}/>
                <Trans i18nKey="misc.more.subtitle"/>
                <span className={"amount"} data-testid={"categoryCount"}></span>
					<span className={"subTitle"} data-testid={"categorySubTitle"}>
						<Trans i18nKey="misc.more.subtitle2"/>
					</span>
                    <span className={"subTitle"} data-testid={"categorySubTitle"}>
				</span>
			</span>
        </a>;
    };

    const elementCount = props.mainCategories.length + (props.otherCategories.length > 0 ? 1 : 0) + (props.markerCategories && props.markerCategories.some(category => category.count > 0) ? 1 : 0)

    return <div className={`page startPage elements-${elementCount}`} data-testid={"searchPage"}>
        <ContrastColorUpdater/>
        <div>
            {renderMarkerCategories()}
            {props.mainCategories.map(category => renderCategory(category))}
            {renderMoreButton()}
        </div>
        {!!props.availableLanguages.length && renderLanguageSelector()}
        {!!props.otherCategories.length && <React.Fragment>
            <CategorySelector categories={props.otherCategories}
                              onClose={() => setIsCategoryMenuOpen(false)} onSelect={() => setIsCategoryMenuOpen(false)}
                              shouldOpen={isCategoryMenuOpen}/>
        </React.Fragment>}
    </div>;
};

export default StartPage;
