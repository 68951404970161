// @flow
import * as React from "react";
import type {Category} from "../models/Category";
import "./CategoryPage.scss";
import type {VehicleOption} from "../models/VehicleOption";
import {CategorySelector} from "./CategorySelector";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom-v5-compat";
import ContrastColorUpdater from "./ContrastColorUpdater";
import {Trans} from "react-i18next";

type CategoryPageProps = {
    onLoad: () => void,
    isIdle: boolean,
    fuzzy: string,
    vehicleOption: VehicleOption,
    mainCategories: Category[],
    otherCategories: Category[],
    highlightColor: string,
    markerCategories: Category[],
};

export const CategoryPage = (props: CategoryPageProps) => {
    const [isCategoryMenuOpen, setIsCategoryMenuOpen] = React.useState(false);
    const {vehicleOption, fuzzy, onLoad} = props;
    const location = useLocation();
    const category = (new URLSearchParams(location.search)).get("category");

    const renderMarkerCategories = () => {
        return props.markerCategories?.some(category => category.count > 0) && (
            props.markerCategories.map(category => (
                <Link to={`/product?marker=SALE`} className="category" key={category.key} data-testid="saleBanner">
                    <span className=" sale-banner"/>
                    <span data-testid={"categoryTitle"}>
                    {category.title}
                        <span className={"amount"} data-testid={"categoryCount"}>{category.count || 0}</span>
                    <span className={"subTitle"} data-testid={"categorySubTitle"}>
                        <Trans i18nKey="category.titleSpecialOffer"/>
                    </span>
                </span>
                </Link>
            ))
        );
    };

    React.useEffect(() => {
            onLoad(category);
        },
        /*eslint-disable-next-line*/
        [vehicleOption, fuzzy, category]
    );

    if (!props.isIdle) {
        return <div data-testid={"loader"}>Loading...</div>;
    }

    const renderCategory = (category: Category) => {
        const categoryStyle = category.imageUrl
            ? {
                backgroundImage: `url(${category.imageUrl})`
            }
            : {
                backgroundImage: "unset"
            };

        const targetUrl = category.categories.length
            ? `/category?category=${category.key}`
            : `/product?category=${category.key}`;

        return (
            <Link to={targetUrl} className={"category"} key={category.key} data-testid={"category"}>
                <span style={categoryStyle}/>
                <span style={{color: props.highlightColor}}>
					{category.title}
                    <span className={"amount"} style={{color: props.highlightColor}}>{category.count}</span>
				</span>
            </Link>);
    };

    if (!props.mainCategories.length) {
        return <div className={"page categoryPage list"} data-testid={"categoryPage"}>
            <ContrastColorUpdater/>
            {props.otherCategories.map(category => renderCategory(category))}
        </div>;
    }

    const renderMoreButton = () => {
        return <a href onClick={() => setIsCategoryMenuOpen(true)} className={"category"} data-testid={"moreLink"}>
            <span style={{
                backgroundImage: "radial-gradient(circle at bottom left, rgba(0,0,0,1) 0%, rgba(59,59,59,1) 100%)",
                backgroundColor: "black",
                color: "white",
                position: "relative",
            }}>
                <span style={{
                    position: "absolute",
                    display: "block",
                    lineHeight: "110%",
                    top: "18px",
                    left: "18px",
                    fontWeight: "normal",
                    textTransform: "uppercase",
                    backgroundColor: "transparent",
                    fontFamily: "Arial, Helvetica, sans-serif",
                }}><Trans i18nKey="misc.more.title2"/></span>
                <span style={{
                    position: "absolute",
                    display: "block",
                    lineHeight: "110%",
                    bottom: "18px",
                    left: "18px",
                    fontWeight: "bold",
                    whiteSpace: "pre-wrap",
                    backgroundColor: "transparent",
                    fontSize: "2.1em"
                }}><Trans i18nKey="misc.more.title"/></span>

            </span>
            <span style={{color: props.highlightColor}}>
                <span className={"amount"} style={{color: props.highlightColor}}/>
                <Trans i18nKey="misc.more.subtitle"/>
                <span className={"amount"} data-testid={"categoryCount"}></span>
					<span className={"subTitle"} data-testid={"categorySubTitle"}>
						<Trans i18nKey="misc.more.subtitle2"/>
					</span>
                    <span className={"subTitle"} data-testid={"categorySubTitle"}>
				</span>
			</span>
        </a>;
    };


    const elementCount = props.mainCategories.length + (props.otherCategories.length > 0 ? 1 : 0) + (props.markerCategories && props.markerCategories.some(category => category.count > 0) ? 1 : 0)

    return <div className={`page categoryPage elements-${elementCount}`} data-testid={"categoryPage"}>
        <ContrastColorUpdater/>
        <div>
            {renderMarkerCategories()}
            {props.mainCategories.map(category => renderCategory(category))}
            {renderMoreButton()}
        </div>
        {!!props.otherCategories.length && <React.Fragment>
            <CategorySelector
                categories={props.otherCategories}
                onClose={() => setIsCategoryMenuOpen(false)} onSelect={() => setIsCategoryMenuOpen(false)}
                shouldOpen={isCategoryMenuOpen}/>
        </React.Fragment>}
    </div>;
};
