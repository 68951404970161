// @flow
import * as React from "react";
import {lazy, Suspense} from "react";
import {BrandContext} from "../components/App";
import {Loading} from "../components/Loading";

type Props = {
	children: any
};

export const ThemeProvider = (props: Props) => {
	const AbarthTheme = lazy(() => import("./abarth/abarth"));
	const AlfaromeoTheme = lazy(() => import("./alfaromeo/alfaromeo"));
	const AudiTheme = lazy(() => import("./audi/audi"));
	const BentleyTheme = lazy(() => import("./bentley/bentley"));
	const BmwTheme = lazy(() => import("./bmw/bmw"));
	const ChryslerTheme = lazy(() => import("./chrysler/chrysler"));
	const CitroenTheme = lazy(() => import("./citroen/citroen"));
	const CupraTheme = lazy(() => import("./cupra/cupra"));
	const DaciaTheme = lazy(() => import("./dacia/dacia"));
	const DodgeTheme = lazy(() => import("./dodge/dodge"));
	const DsTheme = lazy(() => import("./ds/ds"));
	const FiatTheme = lazy(() => import("./fiat/fiat"));
	const FordTheme = lazy(() => import("./ford/ford"));
	const HondaTheme = lazy(() => import("./honda/honda"));
	const HyundaiTheme = lazy(() => import("./hyundai/hyunai"));
	const JaguarTheme = lazy(() => import("./jaguar/jaguar"));
	const JeepTheme = lazy(() => import("./jeep/jeep"));
	const KiaTheme = lazy(() => import("./kia/kia"));
	const LanciaTheme = lazy(() => import("./lancia/lancia"));
	const LandRoverTheme = lazy(() => import("./landRover/landRover"));
	const MaseratiTheme = lazy(() => import("./maserati/maserati"));
	const MazdaTheme = lazy(() => import("./mazda/mazda"));
	const MercedesBenzTheme = lazy(() => import("./mercedesBenz/mercedesBenz"));
	const MiniTheme = lazy(() => import("./mini/mini"));
	const MitsubishiTheme = lazy(() => import("./mitsubishi/mitsubishi"));
	const OpelTheme = lazy(() => import("./opel/opel"));
	const PeugeotTheme = lazy(() => import("./peugeot/peugeot"));
	const PorscheTheme = lazy(() => import("./porsche/porsche"));
	const RenaultTheme = lazy(() => import("./renault/renault"));
	const SeatTheme = lazy(() => import("./seat/seat"));
	const SkodaTheme = lazy(() => import("./skoda/skoda"));
	const SmartTheme = lazy(() => import("./smart/smart"));
	const SubaruTheme = lazy(() => import("./subaru/subaru"));
	const SuzukiTheme = lazy(() => import("./suzuki/suzuki"));
	const ToyotaTheme = lazy(() => import("./toyota/toyota"));
	const VolkswagenTheme = lazy(() => import("./volkswagen/volkswagen"));
	const VolvoTheme = lazy(() => import("./volvo/volvo"));
	const HymerTheme = lazy(() => import("./hymer/hymer"));
	const BmwmoTheme = lazy(() => import("./bmwmo/bmwmo"));


	// eslint-disable-next-line react-hooks/rules-of-hooks
	const brand = React.useContext(BrandContext);
	const renderBrand = () => {
		switch (brand) {
			case ("volvo"):
				return <VolvoTheme>{props.children}</VolvoTheme>;
			case ("subaru"):
				return <SubaruTheme>{props.children}</SubaruTheme>;
			case ("bentley"):
				return <BentleyTheme>{props.children}</BentleyTheme>;
			case ("ford"):
				return <FordTheme>{props.children}</FordTheme>;
			case ("skoda"):
				return <SkodaTheme>{props.children}</SkodaTheme>;
			case ("hyundai"):
				return <HyundaiTheme>{props.children}</HyundaiTheme>;
			case ("volkswagen"):
				return <VolkswagenTheme>{props.children}</VolkswagenTheme>;
			case ("fiat"):
				return <FiatTheme>{props.children}</FiatTheme>;
			case ("citroen"):
				return <CitroenTheme>{props.children}</CitroenTheme>;
			case ("seat"):
				return <SeatTheme>{props.children}</SeatTheme>;
			case ("peugeot"):
				return <PeugeotTheme>{props.children}</PeugeotTheme>;
			case ("abarth"):
				return <AbarthTheme>{props.children}</AbarthTheme>;
			case ("alfaromeo"):
				return <AlfaromeoTheme>{props.children}</AlfaromeoTheme>;
			case ("opel"):
				return <OpelTheme>{props.children}</OpelTheme>;
			case ("smart"):
				return <SmartTheme>{props.children}</SmartTheme>;
			case ("audi"):
				return <AudiTheme>{props.children}</AudiTheme>;
			case ("bmw"):
				return <BmwTheme>{props.children}</BmwTheme>;
			case ("ds automobiles"):
				return <DsTheme>{props.children}</DsTheme>;
			case ("dodge"):
				return <DodgeTheme>{props.children}</DodgeTheme>;
			case ("dacia"):
				return <DaciaTheme>{props.children}</DaciaTheme>;
			case ("jaguar"):
				return <JaguarTheme>{props.children}</JaguarTheme>;
			case ("jeep"):
				return <JeepTheme>{props.children}</JeepTheme>;
			case ("kia"):
				return <KiaTheme>{props.children}</KiaTheme>;
			case ("lancia"):
				return <LanciaTheme>{props.children}</LanciaTheme>;
			case ("landRover"):
				return <LandRoverTheme>{props.children}</LandRoverTheme>;
			case ("mazda"):
				return <MazdaTheme>{props.children}</MazdaTheme>;
			case ("mercedesBenz"):
				return <MercedesBenzTheme>{props.children}</MercedesBenzTheme>;
			case ("mini"):
				return <MiniTheme>{props.children}</MiniTheme>;
			case ("mitsubishi"):
				return <MitsubishiTheme>{props.children}</MitsubishiTheme>;
			case ("porsche"):
				return <PorscheTheme>{props.children}</PorscheTheme>;
			case ("renault"):
				return <RenaultTheme>{props.children}</RenaultTheme>;
			case ("suzuki"):
				return <SuzukiTheme>{props.children}</SuzukiTheme>;
			case ("toyota"):
				return <ToyotaTheme>{props.children}</ToyotaTheme>;
			case ("cupra"):
				return <CupraTheme>{props.children}</CupraTheme>;
			case ("maserati"):
				return <MaseratiTheme>{props.children}</MaseratiTheme>;
			case ("honda"):
				return <HondaTheme>{props.children}</HondaTheme>;
			case ("chrysler"):
				return <ChryslerTheme>{props.children}</ChryslerTheme>;
			case ("hymer"):
				return <HymerTheme>{props.children}</HymerTheme>;
			case ("bmwmo"):
				return <BmwmoTheme>{props.children}</BmwmoTheme>;
			default:
				return <VolvoTheme>{props.children}</VolvoTheme>;
		}
	};

	return (
		<Suspense fallback={<Loading/>}>
			{renderBrand()}
		</Suspense>);
};
