// @flow

import type { ProductFilterQuery } from "../api/ProductService";
import { CONFIG_ID_TYPE, INTENT, ProductService } from "../api/ProductService";
import type { Dispatch } from "redux";
import type { GetState } from "../reducers";
import type { SortOption } from "../components/CategoryProductsPage";
import {
	categoryRequestGotten,
	categorySuccessGotten,
	productRequestGotten,
	productsCategoryRequestGotten,
	productsCategorySuccessGotten,
	productsSearchRequestGotten, productsSearchSuccessGotten,
	productSuccessGotten
} from "../reducers/contentReducer";

export const GET_CATEGORY = {
	REQUEST: "GET_CATEGORY_REQUEST",
	SUCCESS: "GET_CATEGORY_SUCCESS",
	FAILURE: "GET_CATEGORY_FAILURE"
};
export const GET_CATEGORY_PRODUCTS = {
	REQUEST: "GET_CATEGORY_PRODUCTS_REQUEST",
	SUCCESS: "GET_CATEGORY_PRODUCTS_SUCCESS",
	FAILURE: "GET_CATEGORY_PRODUCTS_FAILURE"
};
export const GET_PRODUCT = {
	REQUEST: "GET_PRODUCT_REQUEST",
	SUCCESS: "GET_PRODUCT_SUCCESS",
	FAILURE: "GET_PRODUCT_FAILURE"
};

export const getCategory = (categoryKey: string) => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		dispatch(categoryRequestGotten());

		const dealerId = getState().config.dealerId;
		const APIClient = new ProductService(dealerId, CONFIG_ID_TYPE.DEALER, INTENT.DEFAULT, getState().i18n.languageCode);
		const vehicle = getState().filter.vehicleOption;
		const vehicleKey = vehicle.series ? vehicle.series.key : (vehicle.model ? vehicle.model.key : null);
		const getSelectedCategory = () => {
			let selectedCategory;
			for (let series of getState().vehicles.availableSeries) {
				if (series.key === categoryKey || series.name === categoryKey || series.title === categoryKey) {
					selectedCategory = series;
					return selectedCategory;
				}
				for (let category of series.categories) {
					if (category.key === categoryKey || category.name === categoryKey || category.title === categoryKey) {
						selectedCategory = category;
						return selectedCategory;
					}
				}
			}
			return undefined;
		};

		const selectedCategory = getSelectedCategory();

		const body = {
			dealerId,
			filterParameters: {
				categoryKey,
				excludedCategories: [],
				vehicleKey,
				strictCategoryMatching: false,
			}
		};

		APIClient.getCategoryNavigation(body)
			.then(res => {
				dispatch(categorySuccessGotten({selectedCategory, ...res}));
			})
			.catch((err: string) => {
				console.error(err);
				dispatch({
					type: GET_CATEGORY.FAILURE
				});
			});
	};
};

export const getCategoryProducts = (categories: string[] = [], currentPage: number = 0, sortOption: SortOption[] = []) => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		dispatch(productsCategoryRequestGotten(currentPage));

		const dealerId = getState().config.dealerId;
		const APIClient = new ProductService(dealerId, CONFIG_ID_TYPE.DEALER, INTENT.DEFAULT, getState().i18n.languageCode);
		const vehicle = getState().filter.vehicleOption;
		const vehicleKey = vehicle.series ? vehicle.series.key : (vehicle.model ? vehicle.model.key : null);
		const category = new URLSearchParams(window.location.search).get("category");
		const marker = new URLSearchParams(window.location.search).get("marker");
		const categoryKey = category
			? category
			: ((getState().filter.filter && getState().filters.filter.selectedCategory) ? getState().filter.filter.selectedCategory.key : null);

		const filterParameters: ProductFilterQuery = {
			filterParameters: {
				categoryKey,
				vehicleKey,
				markers: marker ? [marker] : []
			},
			sortOrders: sortOption
		};

		APIClient.getCategoryProducts(currentPage, filterParameters)
			.then(res => {
				dispatch(productsCategorySuccessGotten({...res.pagedProducts, categories}));
			})
			.catch((err: string) => {
				console.error(err);
				dispatch({
					type: GET_CATEGORY_PRODUCTS.FAILURE
				});
			});
	};
};

export const getSearchPageProducts = (fuzzy: string = null, currentPage: number = 0, sortOption: SortOption[] = []) => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		dispatch(productsSearchRequestGotten(currentPage));

		const dealerId = getState().config.dealerId;
		const APIClient = new ProductService(dealerId, CONFIG_ID_TYPE.DEALER, INTENT.DEFAULT, getState().i18n.languageCode);
		const vehicle = getState().filter.vehicleOption;
		const vehicleKey = vehicle.series ? vehicle.series.key : (vehicle.model ? vehicle.model.key : null);
		const category = new URLSearchParams(window.location.search).get("category");
		const fuzzyKey = new URLSearchParams(window.location.search).get("fuzzy") || fuzzy || getState().filter.fuzzy;
		const categoryKey = category
			? category
			: ((getState().filter.filter && getState().filters.filter.selectedCategory) ? getState().filter.filter.selectedCategory.key : null);

		const filterParameters: ProductFilterQuery = {
			fuzzy: fuzzyKey,
			filterParameters: {
				categoryKey,
				vehicleKey
			},
			sortOrders: sortOption
		};

		APIClient.getCategoryProducts(currentPage, filterParameters)
			.then(res => {
				dispatch(productsSearchSuccessGotten({...res.pagedProducts, fuzzy: fuzzyKey}));
			})
			.catch((err: string) => {
				console.error(err);
				dispatch({
					type: GET_CATEGORY_PRODUCTS.FAILURE
				});
			});
	};
};

export const getProductDetails = (productId: string) => {
	return (dispatch: Dispatch<any>, getState: GetState) => {
		if (productId) {
			dispatch(productRequestGotten());

			const dealerId = getState().config.dealerId;
			const APIClient = new ProductService(dealerId, CONFIG_ID_TYPE.DEALER, INTENT.DEFAULT, getState().i18n.languageCode);
			const vehicle = getState().filter.vehicleOption;
			const vehicleKey = vehicle.series ? vehicle.series.key : (vehicle.model ? vehicle.model.key : null);
			const category = new URLSearchParams(window.location.search).get("category");
			const categoryKey = category ? category : (getState().content.selectedCategory ? getState().content.selectedCategory.key : null);

			APIClient.getProductDetails(productId, {categoryKey, vehicleKey})
				.then(res => dispatch(productSuccessGotten(res)))
				.catch((err: string) => {
					console.error(err);
					dispatch({
						type: GET_PRODUCT.FAILURE
					});
				});
		}
	};
};
