// @flow

import Configuration from "../Configuration";
import type {Axios} from "axios";
import axios from "axios";
import type {Vehicle} from "../models/Vehicle";
import type {GarageDealership} from "../../../homepagemodul-2019/src/models/GarageDealership";

export type SortOrder = {
	direction: "ASC" | "DESC",
	fieldname: string,
}

export type PriceRange = {
	from: number,
	to: number
};

export type FilterParameters = {
	categories?: string[],
	categoryKey: string,
	markers?: string[],
	priceRange?: PriceRange,
	vehicle: { series: string, model: string },
	vehicleKey: string,
	productIds?: string[],
}

export type ProductFilterQuery = {
	fuzzy?: string,
	sortOrders?: SortOrder,
	filterParameters: ?FilterParameters,
	vehicle: ?Vehicle,
	categories: any
}

export const emptyFilter = {
	fuzzy: "",
	sortOrder: [{direction: "ASC", fieldname: "score"}],
	filterParameters: null,
	vehicle: null,
	categories: null,
};
export const CONFIG_ID_TYPE = {
	DISTRIBUTOR: "DISTRIBUTOR",
	DEALER: "DEALER",
	GARAGE: "GARAGE",
	GARAGE_GROUP: "GARAGE_GROUP",
};
export const INTENT = {
	DEFAULT: "DEFAULT",
	DISTRIBUTOR: "DISTRIBUTOR",
	EXCLUDE_LIFESTYLE: "EXCLUDE_LIFESTYLE",
	VOLVO_SELEKT: "VOLVO_SELEKT",
};

export class ProductService {
	api: Axios;

	constructor(configId: string, configIdType: string = CONFIG_ID_TYPE.DEALER, intent: string = INTENT.DEFAULT, language: string = "de") {
		this.api = axios.create({
			baseURL: Configuration.value("apiBaseUrl"),
			headers: {
				"Accept-Language": language,
				"Content-Type": "application/json",
				"X-ConfigId": configId,
				"X-ConfigIdType": configIdType,
				"X-Intent": intent,
				"X-ModuleType": "POS"
			},
		});
	}

	getProductDetails = (productId: string, filterParameters: ProductFilterQuery = emptyFilter) => {
		const body = {
			filterParameters
		};
		return this.api.post(`/v2/productDetail/${productId}`, body)
			.then(res => res.data);
	};

	getProduct = (productId: string, latitude: ?number = null, longitude: ?number = null) => {
		return this.api.get(`/v2/product/${productId}`, null, {
			params: {
				latitude,
				longitude
			}
		}).then(response => response.data);
	};

	getCategoryNavigation = (body, maxMainCategories = 7) => {
		return this.api.post(`v2/products/navigation?maxMainCategories=${maxMainCategories}&categoryMarkers=SALE`, body)
			.then(response => response.data);
	};

	getCategoryProducts = (currentPage: number = 0, filterOptions: ProductFilterQuery = emptyFilter) => {
		const body = {
			fuzzy: filterOptions.fuzzy,
			sortOrders: filterOptions.sortOrders,
			filterParameters: filterOptions.filterParameters,
			vehicle: filterOptions.vehicle
		};
		return this.api.post(`/v2/products/search?page=${currentPage}&size=72`, body)
			.then(response => response.data);
	};

	getOverview = (filterParameters: ProductFilterQuery = emptyFilter) => {
		const {fuzzy = "", vehicle, categories = []} = filterParameters;
		const body = {
			fuzzy,
			filterParameters: {
				categories,
				vehicle
			}
		};

		return this.api.post("/v2/products/overview", body)
			.then(response => {
				return {
					categories: response.data.filterParameters,
					meta: response.data.filterStats
				};
			});
	};

	getConfig = async () => {
		return this.api.get("/v2/configure")
			.then(response => response.data);
	};

	getBrands = (): GarageDealership => {
		return this.api.get("/v2/configure")
			.then(response => response.data.garageDealerships);
	};
}
